import {Typography} from "../Typography";
import * as styles from './BlogPostContent.module.scss';
import React from 'react';
import classNames from 'classnames';
import {MarkdownContent} from "../MarkdownContent";

export function BlogPostContent({className, ...otherProps}) {
    return <MarkdownContent  component={Typography}
                             forBlog
                             className={classNames(className, styles.root)} {...otherProps} />;
}
