// extracted by mini-css-extract-plugin
export var aside = "blogPost-module--aside--9uYsA";
export var blogContent = "blogPost-module--blogContent--k1wSP";
export var featuredBlogPosts = "blogPost-module--featuredBlogPosts--a4UUH";
export var mainContent = "blogPost-module--mainContent--YPkSu";
export var mainContentWrapper = "blogPost-module--mainContentWrapper--IUguK";
export var newsletterForm = "blogPost-module--newsletterForm--jHdYN";
export var newsletterFormSmall = "blogPost-module--newsletterFormSmall--ggkth";
export var share = "blogPost-module--share--MYpI0";
export var stickyPart = "blogPost-module--stickyPart--CLAAq";
export var stickyWrapper = "blogPost-module--stickyWrapper--xZg-W";