import React from 'react';
import {GatsbyImage} from "gatsby-plugin-image";

export function SVGSupportImg({url, childImageSharp, gatsbyImgProps, ...otherProps}) {
    if (childImageSharp) {
        let style = {};

        if (childImageSharp.fluid) {
            if (childImageSharp.fluid.presentationWidth) {
                style.width = childImageSharp.fluid.presentationWidth + 'px';
                style.height = childImageSharp.fluid.presentationHeight + 'px';
            }
        } else {
        }

        return (
            <GatsbyImage
                alt=""
                {...getSharpProps(childImageSharp)}
                {...otherProps}
                style={style}
                {...gatsbyImgProps} />
        );
    }
    return <img alt="" src={url} {...otherProps} />;
}

function getSharpProps(sharpProps) {
    if (sharpProps.fluid) {
        let {presentationWidth, presentationHeight, ...finalSharpProps} = sharpProps.fluid;
        return {fluid: finalSharpProps};
    }

    if (sharpProps.gatsbyImageData) {
        return {image: sharpProps.gatsbyImageData};
    }

    return sharpProps;
}
