import React, {useEffect} from 'react';
import {Layout} from "../components/Layout";
import {BlogPostHeader} from "../components/BlogPostHeader";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {ShareLinkBox} from "../components/ShareLinkBox";
import * as styles from './blogPost.module.scss';
import {BlogPostContent} from "../components/BlogPostContent";
import {graphql} from "gatsby";
import {useLocation} from "@reach/router";
import {createSeoData} from "../functions/createSeoData";
import {initSpreaker} from "../functions/initSpreaker";
import {Block} from "../components/Block";
import {NewsletterSignup} from "../components/NewsletterSignup";
import {ArticleJsonLd} from 'gatsby-plugin-next-seo';
import {useCurrentUrl} from "../hooks/useCurrentUrl";
import {useUrlToPath} from "../hooks/useUrlToPath";
import {ServiceBanner} from "../components/ServiceBanner";

export const query = graphql`fragment Seo on ContentfulSeoData {
  title
  author {
    name
  }
  image {
    localFile {
      childImageSharp {
        gatsbyImageData(width: 1200, height: 627, placeholder: BLURRED, layout: FIXED)
      }
    }
  }
  description {
    description
  }
  keywords {
    keywords
  }
  publishDate(formatString: "YYYY-MM-DD")
  modifiedDate
}

query BlogPost($locale: String!, $lang: String!, $slug: String!, $serviceBannersSlugs: [String!], $hasServiceBanners: Boolean!) {
  post: contentfulBlogPost(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    title
    intro {
      childMarkdownRemark {
        excerpt
        html
      }
    }
    createdAt
    publishDate(formatString: "YYYY-MM-DD")
    updatedAt
    content {
      childMarkdownRemark {
        html
      }
    }
    image {
      localFile {
        url
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
    author {
      name
      avatar {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 100, height: 100, placeholder: BLURRED, layout: FIXED)
          }
        }
      }
    }
    seo {
      ...Seo
    }
  }
  serviceBanners: allContentfulServiceV3(
    filter: {slug: {in: $serviceBannersSlugs}, node_locale: {eq: $locale}}
  ) @include(if: $hasServiceBanners) {
    nodes {
      name
      slug
      bannerText
      bannerImage {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 200, placeholder: BLURRED, layout: CONSTRAINED)
          }
        }
      }
    }
  }
  featured: allContentfulBlogPost(
    filter: {isFeatured: {eq: true}, node_locale: {eq: $locale}, language: {eq: $lang}}
    limit: 3
  ) {
    edges {
      node {
        title
        slug
        isFeatured
        category
        createdAt
        language
        author {
          name
        }
        intro {
          childMarkdownRemark {
            html
          }
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 150, height: 135, placeholder: BLURRED, layout: FIXED)
            }
          }
        }
      }
    }
  }
}
`;
export default function BlogPost({data, pageContext}) {
    const location = useLocation();
    const url = location.origin + location.pathname;
    const baseUrl = location.pathname;

    useEffect(() => {
        initSpreaker();
    }, []);

    const currentUrl = useCurrentUrl();
    const urlToPath = useUrlToPath();

    const image = data.post.seo?.image?.localFile?.childImageSharp?.gatsbyImageData.src

    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   enableRecaptchaBadge={true}
                   title={data.post.title}
                   seo={createSeoData(data.post.seo)}
                   lang={pageContext.lang}
                   defaultHrefLang={pageContext.lang}
                   hrefLangs={[pageContext.lang]}>

        <ArticleJsonLd
            url={currentUrl}
            headline={data.post.title}
            description={data.post.intro.childMarkdownRemark.excerpt}
            overrides={{
                '@type': 'BlogPosting',
            }}
            images={image ? [urlToPath(image)] : []}
            publisherName="GGS IT Consulting"
            authorName={data.post.author.name}
            authorType="Person"
            datePublished={data.post.publishDate}
            publisherLogo={urlToPath('logo_light.svg')}
        />

        <PageMainColumnWrapper>
            <div className={styles.mainContentWrapper}>
                <div className={styles.mainContent}>
                    <BlogPostHeader
                        title={data.post.title}
                        publishDate={data.post.publishDate}
                        author={{
                            name: data.post.author.name,
                            avatar: data.post.author.avatar.localFile.childImageSharp
                        }}
                        intro={data.post.intro}
                        image={data.post.image.localFile}
                    />
                    <BlogPostContent className={styles.blogContent} content={[data.post.content]}/>
                </div>
                <div className={styles.aside}>
                    <div className={styles.stickyWrapper}>
                        <div className={styles.stickyPart}>
                            {data.serviceBanners && data.serviceBanners.nodes.map((x, i) => {
                                return <ServiceBanner title={x.name}
                                                      text={x.bannerText}
                                                      key={i}
                                                      slug={x.slug}
                                                      image={x.bannerImage?.localFile}/>
                            })}
                            <ShareLinkBox url={url} baseUrl={baseUrl}
                                          title="Share this article"
                                          className={styles.share}/>
                        </div>
                    </div>


                </div>
            </div>

        </PageMainColumnWrapper>
    </Layout>
}
