import React from 'react';
import classNames from 'classnames';

const styles = require('./Block.module.scss');

export function Block({className, withTypicalMargin = false, appearance, ...otherProps}) {
    const classes = classNames(styles.root, className, withTypicalMargin && styles.withTypicalMargin)
    return <div className={classes} data-appearance={appearance} {...otherProps} />;
}

export function sectionBackgroundToAppearance(background) {
    if (background === 'gray' || background === 'grey') {
        return 'subtle';
    }
    if (background === 'primary') {
        return 'primary';
    }
}
