export function createSeoData(data) {
    if (!data) {
        return {};
    }
    return {
        title: data.title,
        description: data.description?.description,
        keywords: data.keywords?.keywords,
        image: data.image?.localFile?.childImageSharp?.gatsbyImageData.src,
        publishDate: data.publishDate,
        modifiedDate: data.modifiedDate,
        author: data.author?.name
    };
}
