import React from 'react';
import classNames from 'classnames';
import * as styles from './BlogPostHeader.module.scss';
import {Author} from "../Author";
import { GatsbyImage } from "gatsby-plugin-image";
import {BlockTitle} from "../BlockTitle";
import {SVGSupportImg} from "../SVGSupportImg";
import {MarkdownContent} from "../MarkdownContent";
import {Typography} from "../Typography";

export function BlogPostHeader({title, className, author, intro, publishDate, image, ...otherProps}) {

    return <div className={classNames(className, styles.root)} {...otherProps}>
        <div className={styles.content}>
            <BlockTitle className={styles.title}>{title}</BlockTitle>
            <div className={styles.meta}>
                <Author name={author.name} avatar={author.avatar} className={styles.author}/>
                {publishDate && <time className={styles.publishDate} dateTime={publishDate}>{publishDate}</time>}
            </div>
        </div>
        <div className={styles.introWrapper}>
            {image ? <SVGSupportImg {...image} alt={title} className={styles.image}/> : undefined}
            <MarkdownContent content={intro} component={Typography} emphasizeFirstParagraph/>
        </div>
    </div>
}
