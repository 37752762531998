import React, {useEffect, useState} from 'react';
import {BlockTitle} from "../BlockTitle";
import {TextInput} from "../TextInput";
import {Button} from "../Button";
import classNames from "classnames";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {initRecaptcha} from "../../functions/initRecaptcha";
import {useIntl} from "gatsby-plugin-react-intl";
import * as yup from "yup";
import {toast} from "react-toastify";
import {sendNewsletterSignupForm} from "../../functions/sendNewsletterSignupForm";
import {Typography} from "../Typography";

const styles = require('./NewsletterSignup.module.scss');

const schema = yup.object()
    .shape({
        email: yup.string()
            .required('error.required')
            .email('error.email.invalid')
            .trim(),
    });

export function NewsletterSignup({className, ...otherProps}) {

    const [wasFocused, setFocused] = useState(false);

    useEffect(() => {
        if (wasFocused) {
            initRecaptcha();
        }
    }, [wasFocused]);

    const intl = useIntl();

    function translateError(error) {
        if (error) {
            return intl.formatMessage({id: error})
        }
    }

    const onSubmit = (values) => {
        return new Promise((resolve, reject) => {
            global.grecaptcha.ready(function () {
                global.grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'newsletterForm'})
                    .then((token) => {
                        return sendNewsletterSignupForm({
                            captchaToken: token,
                            email: values.email,
                        });
                    })
                    .then(() => {
                        reset();
                        toast.success(intl.formatMessage({id: 'newsletter.form.successMessage'}), {
                            autoClose: 10000,
                            position: "top-center"
                        });
                    })
                    .catch(reject)
            });
        })
    };

    const {handleSubmit, control, errors, formState, reset} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onBlur',
        mode: 'onBlur',
        defaultValues: {
            email: '',
            message: '',
            agreement: false,
            name: ''
        }
    });

    return <form className={classNames(styles.root, className)}
                 {...otherProps}
                 onSubmit={handleSubmit(onSubmit)}>
        <BlockTitle className={styles.title}>Subscribe to our newsletter</BlockTitle>
        <Typography className={styles.description}>
            We break down business processes and share automation ideas so that you can inspire and improve your
            business.
        </Typography>
        <div className={styles.inputWrapper}>
            <Controller
                control={control}
                name="email"
                render={(props) => {
                    return <TextInput {...props}
                                      className={styles.input}
                                      onFocus={() => setFocused(true)}
                                      placeholder="Your Email"
                                      disabled={formState.isSubmitting}
                                      error={translateError(errors.email?.message)}
                    />
                }}
            />
            <Button component="button"
                    className={styles.button}
                    type="submit"
                    disabled={formState.isSubmitting} noHover>Subscribe</Button>
        </div>
    </form>
}
